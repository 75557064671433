import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Content from '../../components/Content';
import Layout from '../../components/Layout';

const IndexPage: FC<PageProps> = function ({ location: { pathname } }) {
  return (
    <Layout pathname={pathname} showTitle title="pages.a-propos.title">
      <Content size="md">
        <p>
          Barrois Antiques propose un vaste choix d'antiquités de tous types du
          18ème siècle aux années 1970 avec une spécialisation pour les pièces
          du 20ème siècle et pour les luminaires.
        </p>

        <p>
          Notre Showroom Barrois Antiques (700m2) est situé au 53 rue du Vieux
          Four à Marcq-en-Baroeul (59700) dans le Nord de la France, à 5mn de
          Lille, 15mn de la frontière belge, 55mn de Paris par TGV, 30 mn de
          Bruxelles et 1h30 de Londres.
        </p>

        <p>
          N'hésitez-pas à nous contacter pour tout renseignement ou à nous
          rendre visite dans notre showroom.
        </p>

        <p>Nous sommes ouverts 7jrs/7 sur Rendez-vous.</p>

        <p>
          Nous ne vendons pas de reproduction, uniquement des pièces
          authentiques.
        </p>

        <p>
          Nous organisons la livraison de vos achats en France comme partout
          dans le Monde.
        </p>
      </Content>
    </Layout>
  );
};

export default IndexPage;
